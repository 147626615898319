import { useEffect, useState } from 'react';
import HTMLViewer from './HTMLViewer';

const ReportTemplateViewer = () => {
    const [templateState, setTemplateState] = useState(null);
    const [ready, setReady] = useState(false);
    
    useEffect(() => {
        const onMessage = e => {
            if (e.origin !== window.origin) return;
            
            const { eventKind, data } = e.data;

            switch (eventKind) {
                case 'stateChange': {
                    setTemplateState(data);
                    break;
                }

                default: {/* no-op */}
            }
        };

        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);

    useState(() => {
        if (ready) return;

        window.opener.postMessage({ eventKind: 'ready' });
        setReady(true);
    }, [ready]);

    if (!templateState) {
        return null;
    }

    return (
        <HTMLViewer
            metadata={templateState.metadata}
            pdfTemplate={templateState.pdfTemplate}
            inputDefinitions={templateState.inputDefinitions}
            userContext={templateState.userContext}
            textVariations={templateState.textVariations}
            chosenLanguage={templateState.chosenLanguage}
            selectedBlockID={templateState.selectedBlockID}
            setSelectedBlockID={blockID => window.opener.postMessage({ eventKind: 'blockSelected', data: blockID })}
            scrollToBlockID={blockID => window.opener.postMessage({ eventKind: 'scrollToBlock', data: blockID })}
        />
    );
};

export default ReportTemplateViewer;