import React from 'react';
import { Loader, Segment, Transition } from 'semantic-ui-react';
import ValidationError from '../Detail/ValidationError';

import styles from './TestHTMLViewer.module.css';

const TestHTMLViewer = ({ virtualHTMLDocument, error, pdfTemplate, inputDefinitions, textVariations, selectedBlockID, setSelectedBlockID, scrollToBlockID, isRunning }) => {
    const renderDocumentElement = virtualHTMLElement => {
        const {
            tag,
            style,
            children,
            innerText,
            buildingBlockID,
            ...other
        } = virtualHTMLElement;

        let className = other.class || '';

        const extraStyle = {};
        const extraProps = {};
        if (buildingBlockID) {
            const isSelected = buildingBlockID === selectedBlockID;

            extraStyle.cursor = 'pointer';

            if (error && error.data.blockID === buildingBlockID) {
                className += ` ${styles.faultyBlock}`;
            } else if (isSelected) {
                className += ` ${styles.selectedBlock}`;
            }

            extraProps.onClick = e => {
                e.stopPropagation();
                
                if (buildingBlockID === selectedBlockID) return;

                const clickedBlock = pdfTemplate.blocks.find(block => block.id === buildingBlockID);
                setSelectedBlockID(clickedBlock.id);
            };

            extraProps.onDoubleClick = e => {
                e.stopPropagation();
                scrollToBlockID(buildingBlockID);
            };
        }

        const props = {
            style: { ...style, ...extraStyle },
            className: className.trim(),
            ...other,
            ...extraProps,
        };

        const convertedChildren = children.map(child => {
            if (typeof child === 'string') {
                return child;
            }

            return renderDocumentElement(child);
        });

        return React.createElement(tag, props, convertedChildren.length > 0 ? convertedChildren : undefined);
    };

    const documentWrapper = (
        <div style={{ all: 'initial' }}>
            {virtualHTMLDocument && renderDocumentElement(virtualHTMLDocument)}
        </div>
    );

    const validationError = error && (
        <ValidationError
            error={error.data}
            pdfTemplate={pdfTemplate}
            setSelectedBlockID={setSelectedBlockID}
            inputDefinitions={inputDefinitions}
            textVariations={textVariations}
        />
    );

    return (
        <div>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 1,
                    right: 0,
                }}
            >
                <Transition visible={isRunning} animation='fade down'>
                    <div
                        style={{
                            padding: '1em',
                            backgroundColor: 'white',
                            borderBottomLeftRadius: '1em',
                            borderLeft: '1px solid lightgray',
                            borderBottom: '1px solid lightgray',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Loader inline active size='tiny' />
                        &nbsp;&nbsp;
                        <strong>Generating...</strong>
                    </div>
                </Transition>
            </div>
            {validationError && (
                <Segment
                    style={{
                        position: 'fixed',
                        top: '1em',
                        left: '1em',
                        padding: 0,
                        zIndex: 100,
                    }}
                    raised
                    children={validationError}
                />
            )}
            {documentWrapper}
        </div>
    );
};

export default TestHTMLViewer;