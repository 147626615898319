import React, { Component }               from 'react';
import uuid                               from 'uuid/v4';
import lodash                             from 'lodash';
import {
    Input,
    Message,
    Grid,
    Radio,
    Segment,
    Header,
    Button,
    Icon,
    List,
    Checkbox
} from 'semantic-ui-react';
import interpret                          from '@digital-revisor/node-formula-interpreter';
import findAllReferences                  from '@digital-revisor/node-formula-interpreter/lib/findAllReferences';

import http                               from 'http/modelEditor';
import { move }                           from 'shared/array';
import { confirm }                        from 'shared/globalModal';
import erpSystems                         from 'shared/erpSystems';
import TextArea                           from 'atoms/TextArea/TextArea';
import FormulaInput                       from 'model-editor/molecules/FormulaInput';

import Documentation                      from '../NodeDataCalculation/documentation';
import { DragAndDropZone, DraggableItem } from '../../../atoms/DragAndDrop';
import styles                             from './NodeDataChecklist.module.css';

const DROPPABLE_ID = 'dnd_checklist';

function Rule ({ open, rule, signatures, modelTags, onChange, onClick, onDelete, i }) {
    const { failMessage, expression, runLastYear, excludeFromLog } = rule;

    const codeChanged = code => {
        rule.expression = code;
        onChange(rule);
    };

    const failMessageChanged = failMsg => {
        rule.failMessage = failMsg;
        onChange(rule);
    };

    const runLastYearChanged = runLastYear => {
        rule.runLastYear = runLastYear;
        onChange(rule);
    };

    const excludeFromLogChanged = excludeFromLog => {
        rule.excludeFromLog = excludeFromLog;
        onChange(rule);
    };

    const failMessageToHeader = failMsg => {
        // cut "failMsg" from idx 0 to first idx of one of these chars
        const cutChars = new Set(['.', '\n', '!', '?', '-']);

        for (let i in [...failMsg]) {
            const c = failMsg[i];

            if (cutChars.has(c)) {
                return failMsg.substring(0, i);
            }
        }

        return failMsg;
    };

    const renderContent = () => {
        if (!open) {
            return;
        }
        return <>
            <br />
            <TextArea
                defaultValue={failMessage}
                onChange={(_, { value }) => failMessageChanged(value)}
                placeholder='Indtast fejlbesked'
                resize='vertical'
                className={styles.errMsgTxa}
                fluid
            />
            <br />
            <br />
            <Checkbox
                defaultChecked={!!runLastYear}
                onChange={(_, { checked }) => runLastYearChanged(checked)}
                label='Kør tjek på sidste års tal'
                toggle
            />
            <br />
            <br />
            <Checkbox
                defaultChecked={!!excludeFromLog}
                onChange={(_, { checked }) => excludeFromLogChanged(checked)}
                label='Ekskludér tjek fra logfil'
                toggle
            />
            <br />
            <br />
            <FormulaInput
                value={expression}
                onChange={codeChanged}
                documentation={signatures}
                referenceNames={modelTags}
            />
        </>;
    };

    let className = styles.unselectedCheck;
    if (open) {
        className = '';
    }
    return <List.Item onClick={onClick}>
        <Segment className={className}>
            <Icon name={open ? 'caret down' : 'caret right'} />
            Regel #{i + 1} {failMessage && `- ${failMessageToHeader(failMessage)}`}
            <Icon
                name='x'
                className={styles.closeicon}
                onClick={onDelete}
            />
            <br />
            {renderContent()}
        </Segment>
    </List.Item>;
}

class RuleManager extends Component {
    state = {
        openIndex: -1
    };
    
    renderNewRuleButton = () => (
        <Button
            content='Ny regel'
            labelPosition='left'
            icon='plus'
            size='small'
            onClick={this.newRuleClicked}
        />
    );

    createNewRule = () => ({
        id: uuid(),
        failMessage: '',
        expression: ''
    });

    newRuleClicked = () => {
        const { rules } = this.props;
        const rule = this.createNewRule();
        rules.push(rule);
        this.props.onChange(rules);
        this.setState({ openIndex: rules.length - 1 });
    };

    ruleChanged = (rule, i) => {
        const { rules } = this.props;
        rules[i] = rule;
        this.props.onChange(rules);
    };

    ruleDeleted = i => {
        const { rules } = this.props;
        rules.splice(i, 1);
        this.props.onChange(rules);
    };

    renderRule = (rule, i) => {
        const { openIndex } = this.state;
        const { signatures, modelTags } = this.props;
        return <Rule
            onChange={r => this.ruleChanged(r, i)}
            onDelete={() => this.ruleDeleted(i)}
            onClick={() => this.setState({ openIndex: i })}
            i={i}
            rule={rule}
            key={rule.id}
            open={openIndex === i}
            signatures={signatures}
            modelTags={modelTags}
        />;
    };

    renderRules = () => (
        <List>
            {this.props.rules.map(this.renderRule)}
        </List>
    );

    render () {
        return <>
            {this.renderNewRuleButton()}
            {this.renderRules()}
        </>;
    }
}

class ChecklistManager extends Component {
    state = {
        selectedCheck: -1,
        hideDetails: false
    };

    newCheckClicked = () => {
        const { checklist } = this.props;
        const check = this.createNewCheck();
        checklist.push(check);
        this.props.onChange(checklist);
        this.setState({ selectedCheck: checklist.length - 1 });
    };

    createNewCheck = () => {
        return {
            id: uuid(),
            title: '',
            rules: []
        };
    };

    deleteCheck = async idx => {
        const sure = await confirm('Er du sikker på at du vil slette tjekket?');
        if (sure) {
            const { checklist } = this.props;
            checklist.splice(idx, 1);
            this.props.onChange(checklist);
        }
    };

    onDragEnd = async result => {
        // Dropped outside the list
        if (!result.destination) {
            return this.setState({ hideDetails: false });
        }
        const { selectedCheck } = this.state;
        const { checklist } = this.props;
        let id;
        if (selectedCheck !== -1) {
            id = checklist[selectedCheck].id;
        }
        const idxA = result.source.index;
        const idxB = result.destination.index;
        move(checklist, idxA, idxB);
        this.props.onChange(checklist);
        this.setState({
            selectedCheck: checklist.findIndex(c => c.id === id),
            hideDetails: false
        });
    };

    setSelectedCheck = selectedCheck => {
        return () => {
            this.setState({ selectedCheck });
        };
    };

    deselectCheck = () => this.setSelectedCheck(-1);

    renderCheckItem = ({ title, id, rules }, i) => {
        const { selectedCheck } = this.state;
        const isSelected = selectedCheck === i;
        const caret = isSelected ? 'caret down' : 'caret right';
        title = title || <i>Mangler titel</i>;
        const checkTitle = isSelected ? <b>{title}</b> : title;
        const aor = rules.length;
        return (
            <DraggableItem
                id={id}
                key={id}
                index={i}
            >
                <Message
                    onClick={this.setSelectedCheck(i)}
                    className={isSelected ? '' : styles.unselectedCheck}
                    onDismiss={() => this.deleteCheck(i)}
                >
                    <Icon name={caret} /> {checkTitle} ({aor} reg{aor === 1 ? 'el' : 'ler'})
                    {isSelected && this.renderCheckDetail(this.props.checklist[selectedCheck])}
                </Message>
            </DraggableItem>
        );
    };

    renderChecklist = checklist => {
        return <DragAndDropZone
            droppableId={DROPPABLE_ID}
            onDragEnd={this.onDragEnd}
            onBeforeDragStart={() => this.setState({ hideDetails: true })}
            children={checklist.map(this.renderCheckItem)}
        />;
    };

    updateSelected = (prop, value) => {
        const { checklist } = this.props;
        const { selectedCheck } = this.state;
        const check = checklist[selectedCheck];
        check[prop] = value;
        this.props.onChange(checklist);
    };

    updateSelectedTitle = val => this.updateSelected('title', val);

    updateSelectedRules = val => this.updateSelected('rules', val);

    updateSelectedShouldSkipERP = val => this.updateSelected('shouldSkipERP', val);

    updateSelectedHasSkipRule = val => this.updateSelected('hasSkipRule', val);

    updateSelectedSkipRuleExpression= val => this.updateSelected('skipRuleExpression', val);

    updateSelectedErpSystems = val => this.updateSelected('selectedErpSystems', val);

    renderSelectedCheck = () => {
        const { selectedCheck } = this.state;
        if (selectedCheck === -1) {
            return;
        }
        const { checklist } = this.props;
        const check = checklist[selectedCheck];
        return this.renderCheckDetail(check);
    };

    renderERPPicker = ({ shouldSkipERP, selectedErpSystems }) => {
        const systems = new Set(selectedErpSystems);
        let erpOptions;
        if (shouldSkipERP) {
            erpOptions = erpSystems.map(({ name, id }) => {
                const onChange = (_, { checked }) => {
                    if (checked) {
                        systems.add(id);
                    } else {
                        systems.delete(id);
                    }
                    this.updateSelectedErpSystems([...systems]);
                };
                return <List.Item>
                    <Checkbox
                        onChange={onChange}
                        checked={systems.has(id)}
                        label={name}
                        id={id}
                    />
                </List.Item>;
            });
        }
        return <List>{erpOptions}</List>;
    };

    renderSkipRuleFormulaInput = check => {
        const { hasSkipRule, skipRuleExpression } = check;
        if (!hasSkipRule) {
            return null;
        }

        const { signatures, modelTags } = this.props;

        return (
            <>
                <br />
                <br />
                <FormulaInput
                    value={skipRuleExpression?.expression}
                    onChange={expression => this.updateSelectedSkipRuleExpression({ expression })}
                    documentation={signatures}
                    referenceNames={modelTags}
                    placeholder='Regelgruppen springes over hvis formlen evalueres til 1...'
                />
            </>
        );
    };

    renderCheckDetail = check => {
        if (this.state.hideDetails) {
            return;
        }
        const { id, title, rules, shouldSkipERP, hasSkipRule } = check;
        return <Segment key={id} basic>
            <Input
                fluid
                placeholder='Titel på tjek'
                defaultValue={title}
                onChange={(_, { value }) => this.updateSelectedTitle(value)}
            />
            <br />
            <Checkbox
                toggle
                label='Spring tjek over ved bestemte ERP-systemer?'
                checked={shouldSkipERP}
                onChange={(_, { checked }) => this.updateSelectedShouldSkipERP(checked)}
            />
            {this.renderERPPicker(check)}
            <Checkbox
                toggle
                label='Spring tjek over ved brug af formel?'
                checked={hasSkipRule}
                onChange={(_, { checked }) => this.updateSelectedHasSkipRule(checked)}
            />
            {this.renderSkipRuleFormulaInput(check)}
            <br />
            <br />
            <RuleManager
                rules={rules}
                signatures={this.props.signatures}
                modelTags={this.props.modelTags}
                onChange={rules => this.updateSelectedRules(rules)}
            />
        </Segment>;
    };

    render () {
        const { checklist } = this.props;
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            content='Nyt tjek'
                            labelPosition='left'
                            icon='plus'
                            onClick={this.newCheckClicked}
                            primary
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {this.renderChecklist(checklist)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

class NodeDataChecklist extends Component {
    state = {
        loading: true,
        title: '',
        errorChecks: [],
        warnChecks: [],
        renderErrorChecks: true // true = render error checks, false = render warn checks
    };

    prepareChecklist = checklist => {
        const parseRuleExpression = rule => {
            const parsedExpression = interpret(rule.expression);
            const requiredTags = findAllReferences(parsedExpression);
            return {
                parsedExpression: JSON.stringify(parsedExpression),
                requiredTags,
            };
        };

        for (const check of checklist) {
            const { rules, hasSkipRule, skipRuleExpression } = check;

            if (hasSkipRule && skipRuleExpression) {
                Object.assign(skipRuleExpression, parseRuleExpression(skipRuleExpression));
            }

            for (const rule of rules) {
                Object.assign(rule, parseRuleExpression(rule));
            }
        }
    };

    getData = () => {
        const { errorChecks, warnChecks, title } = this.state;
        this.prepareChecklist(errorChecks);
        this.prepareChecklist(warnChecks);
        return { errorChecks, warnChecks, title };
    };

    componentDidMount = async () => {
        const signatures = await http.getCalculationFunctions();
        this.setState({
            signatures,
            loading: false,
            errorChecks: lodash.get(this.props, 'node.data.errorChecks') || [],
            warnChecks: lodash.get(this.props, 'node.data.warnChecks') || [],
            title: lodash.get(this.props, 'node.data.title') || ''
        });
    };

    setListType = renderErrorChecks => this.setState({ renderErrorChecks });

    renderListSwitcher = () => {
        const { renderErrorChecks } = this.state;
        return <Segment secondary>
            <Radio
                label='Vis fejlliste'
                readOnly
                onClick={() => this.setListType(true)}
                checked={renderErrorChecks}
            />
            <br />
            <Radio
                label='Vis advarselsliste'
                readOnly
                onClick={() => this.setListType(false)}
                checked={!renderErrorChecks}
            />
        </Segment>;
    };

    getModelTags = () => {
        const out = [];
        const { nodes } = this.props;
        for (let node of nodes) {
            if (node.tag) {
                out.push(node.tag);
            }
        }
        out.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        });
        return out;
    };

    render () {
        const { loading, signatures, title, renderErrorChecks } = this.state;
        if (loading) {
            return null;
        }
        const modelTags = this.getModelTags();
        const listToRender = renderErrorChecks ? 'errorChecks' : 'warnChecks';
        const header       = renderErrorChecks ? 'Fejl'        : 'Advarsler';
        return <>
            <Documentation
                documentation={signatures}
                buttonText='Vis udregningshjælp'
            />
            <Segment className={styles.container}>
                <Input
                    onChange={(_, { value }) => this.setState({ title: value })}
                    defaultValue={title}
                    label='Titel'
                    placeholder='Indtast tjeklistetitel'
                    labelPosition='left'
                    fluid
                />
                {this.renderListSwitcher()}
                <Header>{header}</Header>
                <ChecklistManager
                    key={listToRender}
                    signatures={signatures}
                    modelTags={modelTags}
                    checklist={this.state[listToRender]}
                    onChange={list => this.setState({ [listToRender]: list })}
                />
            </Segment>
        </> ;
    }
}

export default NodeDataChecklist;