import React, { useState } from 'react';
import { Card, Icon, Header, Input, Segment } from 'semantic-ui-react';
import { nodeMetadata, nodeCategories } from '../../nodeMetadata';

function categorizeNodes (query) {
    const out = {};
    for (let category of Object.keys(nodeCategories)) {
        out[category] = [];
    }
    for (let [ name, nodeData ] of Object.entries(nodeMetadata)) {
        const { category, immutable, deprecated } = nodeData;
        if (immutable) {
            continue;
        }

        if (deprecated) {
            continue;
        }

        if (query && !name.toLowerCase().includes(query.toLowerCase())) {
            continue;
        }

        nodeData.name = name;
        out[category].push(nodeData);
    }
    return out;
}

function NodeCard ({ name, color, selected, onClick }) {
    const cardStyle = {};
    const headerStyle = {};
    if (selected) {
        cardStyle.backgroundColor = '#21ba45';
        headerStyle.color = 'white';
    } else {
        headerStyle.color = color;
    }
    return (
        <Card onClick={() => onClick(name)}>
            <Card.Content textAlign='center' style={cardStyle}>
                <h5 style={headerStyle}>
                    {name}
                </h5>
            </Card.Content>
        </Card>
    );
}

function NodeTypeSelector ({ onChange, selected }) {
    const [query, setQuery] = useState('');
    const nodes = categorizeNodes(query);
    const groups = [];
    for (let [category, nodeList] of Object.entries(nodes)) {
        if (nodeList.length === 0) {
            continue;
        }

        const tcategory = nodeCategories[category];
        const cards = nodeList.map(({ name, color }) => {
            const isSelected = name === selected;
            return <NodeCard
                name={name}
                color={color}
                selected={isSelected}
                onClick={onChange}
            />;
        });
        const group = <>
            <Header>
                <Icon name={tcategory.icon} />
                {tcategory.name}
            </Header>
            <Card.Group itemsPerRow={4} stackable>
                {cards}
            </Card.Group>
        </>;
        groups.push(group);
    }
    return (
        <Segment style={{ marginTop: 0 }}>
            <Input
                onChange={(_, { value }) => setQuery(value)}
                icon='search'
                iconPosition='left'
                placeholder='Søg efter nodetype...'
            />
            {groups}
        </Segment>
    );
}

export default NodeTypeSelector;