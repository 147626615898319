import React, { useEffect, useState } from 'react';
import reportEditor from 'http/reportEditor';
import TestHTMLViewer from './TestHTMLViewer';
import { collectTestVariables } from '../Detail/PDFView';

const HTMLViewer = ({ metadata, pdfTemplate, inputDefinitions, userContext, textVariations, chosenLanguage, selectedBlockID, setSelectedBlockID, scrollToBlockID }) => {
    const [virtualHTMLDocument, setVirtualHTMLDocument] = useState(null);
    const [error, setError] = useState(null);
    const [nextUpdate, setNextUpdate] = useState(null);
    const [isRunning, setIsRunning] = useState(false);
    
    const convertTestTemplateToDocumentComponents = async (update) => {
        const { pdfTemplate, inputDefinitions, userContext, metadata, textVariations, chosenLanguage } = update;

        const variables = collectTestVariables(inputDefinitions);

        const template = {
            metadata,
            inputDefinitions,
            pdfTemplate,
            textVariations,
        };

        const userContextToUse = {
            ...userContext,
            language: chosenLanguage || userContext.language,
        };

        const outputType = 'virtualHtmlDocument';
        const virtualHTMLDocument = await reportEditor.testTemplate({ variables, template, userContext: userContextToUse }, outputType);

        return virtualHTMLDocument;
    };

    useEffect(() => {
        setNextUpdate({
            pdfTemplate,
            inputDefinitions,
            userContext,
            metadata,
            textVariations,
            chosenLanguage,
        });
    }, [pdfTemplate, inputDefinitions, userContext, metadata, textVariations, chosenLanguage]);

    useEffect(() => {
        if (isRunning) return;
        if (!nextUpdate) return;

        const updateToDo = nextUpdate;

        setNextUpdate(null);
        setIsRunning(true);

        convertTestTemplateToDocumentComponents(updateToDo)
            .then(virtualHTMLDocument => {
                setVirtualHTMLDocument(virtualHTMLDocument);
                setError(null);
            })
            .catch(err => setError({ validationError: true, data: err.data }))
            .finally(() => {
                setIsRunning(false);
            });
    }, [nextUpdate, isRunning]);

    return (
        <TestHTMLViewer
            virtualHTMLDocument={virtualHTMLDocument}
            error={error}
            isRunning={isRunning || !!nextUpdate}

            pdfTemplate={pdfTemplate}
            inputDefinitions={inputDefinitions}
            textVariations={textVariations}
            selectedBlockID={selectedBlockID}
            setSelectedBlockID={setSelectedBlockID}
            scrollToBlockID={scrollToBlockID}
        />
    );
};

export default HTMLViewer;