import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Icon, Loader, Menu, Segment, Transition } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import TaxonomyDatalist from 'model-editor/atoms/TaxonomyDataList';
import ColoredText from 'atoms/ColoredText';
import reportEditor from 'http/reportEditor';
import modelEditor from 'http/modelEditor';
import TaxonomyParser from 'http/taxonomyParser';
import useRestResource from 'shared/hooks/useRestResource';
import crc32 from 'shared/crc32';

import model from './model';
import buildSuggestionsFromDefinitions from './buildSuggestionsFromDefinitions';
import { setConfigValue, useReportTemplatesConfig } from './config';

import { expandTargetBlock } from './blockService';
import FormulaHelp from './FormulaHelp';
import PreviewWindowManager from './PreviewWindowManager';

import MetadataEditor from './MetadataEditor';
import InputEditor from './InputEditor';
import BlockEditor from './BlockEditor';
import PDFView from './PDFView';
import Export from './Export';

export const tabIds = {
    general: 'general',
    inputs: 'inputs',
    blocks: 'blocks',
    pdf: 'pdf',
    export: 'export',
};

const getChecksum = (thing) => {
    return crc32(JSON.stringify(thing));
};

export const goToBuildingBlock = ({ blockID, schemaCategory, pdfTemplate, setPdfTemplate, setTab }) => {
    const blocks = [...pdfTemplate?.blocks];
    expandTargetBlock(blocks, blockID);
    setPdfTemplate({
        ...pdfTemplate,
        blocks,
    });

    const categoryIndex = Object.keys(model.schemaCategories).findIndex(category => {
        return category === schemaCategory || model.schemaCategories.meta;
    });

    setConfigValue('selectedBlockTab', categoryIndex === -1 ? 0 : categoryIndex);
    setConfigValue('selectedField', blockID);
    setTab(tabIds.blocks);
};

const ReportTemplatesDetail = () => {
    const { templateID } = useParams();
    const reportTemplate = useRestResource({
        fetcher: reportEditor.getTemplate,
        args: [templateID],
    });
    const calculationFunctions = useRestResource({
        fetcher: modelEditor.getCalculationFunctions,
        args: [],
    });

    const [initDone, setInitDone] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [checksums, setChecksums] = useState([]);
    const [taxonomy, setTaxonomy] = useState({});
    const [isParsingTaxonomy, setIsParsingTaxonomy] = useState(false);
    const [activeTabIdx, setActiveTabIdx] = useReportTemplatesConfig('activeMainTab', 0);
    const [chosenLanguage, setChosenLanguage] = useReportTemplatesConfig('chosenLanguage');

    const [metadata, setMetadata] = useState({});
    const [inputDefinitions, setInputDefinitions] = useState([]);
    const [userContext, setUserContext] = useState([]);
    const [textVariations, setTextVariations] = useState([]);
    const [pdfTemplate, setPdfTemplate] = useState({});

    const suggestions = useMemo(() => {
        return buildSuggestionsFromDefinitions(inputDefinitions);
    }, [inputDefinitions]);

    const containerRef = useRef();

    useEffect(() => {
        if (!metadata.taxonomy) {
            return;
        }

        (async () => {
            setIsParsingTaxonomy(true);
            const { taxonomy: taxonomyID } = metadata;

            const taxonomyTags = await TaxonomyParser.listTags(taxonomyID);
            const taxonomyTagsByName = {};
            for (const tag of taxonomyTags) {
                if (tag.name in taxonomyTagsByName) continue;
                taxonomyTagsByName[tag.name] = tag;
            }

            setTaxonomy(taxonomyTagsByName);
            setIsParsingTaxonomy(false);
        })();
    }, [metadata, metadata.taxonomy, metadata.supportedLanguages]);

    useEffect(() => {
        if (initDone) {
            return;
        }

        if (reportTemplate.loading) {
            return;
        }

        const {
            metadata         = {},
            inputDefinitions = [],
            userContext      = {},
            textVariations   = [],
            pdfTemplate      = {},
        } = reportTemplate.data.data;

        for (let block of pdfTemplate.blocks || []) {
            if (block.kind === model.blockTypes.groupCloser.id) {
                continue;
            }

            if (block.data) {
                continue;
            }

            block.data = {};
        }

        setMetadata(metadata);
        setInputDefinitions(inputDefinitions);
        setUserContext(userContext);
        setTextVariations(textVariations);
        setPdfTemplate(pdfTemplate);
        setChecksums([metadata, inputDefinitions, userContext, textVariations, pdfTemplate].map(getChecksum));
        setInitDone(true);
    }, [initDone, reportTemplate, setMetadata, setInputDefinitions, setUserContext, setPdfTemplate]);

    useEffect(() => {
        if (!metadata) return;
        if (metadata.supportedLanguages?.includes(chosenLanguage)) return;
        
        const firstSupportedLanguage = metadata?.supportedLanguages?.at(0);
        if (firstSupportedLanguage) {
            setChosenLanguage(firstSupportedLanguage);
        }
    }, [metadata, chosenLanguage, setChosenLanguage]);

    useEffect(() => {
        const debounce = window.setTimeout(() => {
            const newChecksums = [metadata, inputDefinitions, userContext, textVariations, pdfTemplate].map(getChecksum);
            const changesMade = checksums.some((sum, idx) => sum !== newChecksums[idx]);
            setChangesMade(changesMade);
        }, 500);

        return () => {
            window.clearTimeout(debounce);
        };
    }, [checksums, metadata, inputDefinitions, userContext, textVariations, pdfTemplate]);

    const tabs = [
        {
            id: tabIds.general,
            name: 'General',
            icon: 'info circle',
            Component: MetadataEditor,
            scrollTopOnChange: false,
        },
        {
            id: tabIds.inputs,
            name: 'Inputs',
            icon: 'unordered list',
            Component: InputEditor,
            scrollTopOnChange: false,
        },
        {
            id: tabIds.blocks,
            name: 'Blocks',
            icon: 'block layout',
            Component: BlockEditor,
            scrollTopOnChange: false,
        },
        {
            id: tabIds.pdf,
            name: 'PDF',
            icon: 'file pdf',
            Component: PDFView,
            scrollTopOnChange: true,
            hidden: metadata?.outputAsIXBRL && !metadata.documentMustBePrintableAsPDF,
        },
        {
            id: tabIds.export,
            name: 'Publish',
            icon: 'upload',
            Component: Export,
            scrollTopOnChange: true,
        },
    ];

    const setTab = tabID => {
        setActiveTabIdx(tabs.findIndex(tab => tab.id === tabID));
    };

    const handleSave = async () => {
        setIsSaving(true);

        await reportEditor.updateTemplate(templateID, {
            metadata,
            inputDefinitions,
            userContext,
            textVariations,
            pdfTemplate,
        });

        setChecksums([
            metadata,
            inputDefinitions,
            userContext,
            textVariations,
            pdfTemplate,
        ].map(getChecksum));

        setChangesMade(false);
        setIsSaving(false);
    };

    const renderActiveComponent = () => {
        const isReady = (
            initDone &&
            !calculationFunctions.loading
        );

        if (!isReady) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Loader active inline='centered' size='big' />
                    Loading template...
                </div>
            );
        }

        const activeTab = tabs[activeTabIdx];
        if (activeTab.hidden) {
            return null;
        }

        const ActiveComponnent = activeTab.Component;

        const props = {
            // template sub-documents + setters
            metadata,
            inputDefinitions,
            userContext,
            pdfTemplate,
            textVariations,
            setMetadata,
            setInputDefinitions,
            setUserContext,
            setPdfTemplate,
            setTextVariations,
            
            // other
            calculationFunctions: calculationFunctions.data,
            changesMade,
            taxonomy,
            isParsingTaxonomy,
            suggestions,
            templateID,
            setTab,
        };

        return (
            <ActiveComponnent {...props} />
        );
    };

    const setActiveTab = idx => {
        if (idx === activeTabIdx) {
            return;
        }

        setActiveTabIdx(idx);

        const targetTab = tabs[idx];
        if (targetTab.scrollTopOnChange) {
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div style={{ marginLeft: '1em', marginRight: '1em' }} ref={containerRef}>
            <Menu attached='top' style={{ marginTop: '1em', position: 'sticky', top: 0, zIndex: 1 }}>
                {Object.values(tabs).map((tab, idx) => {
                    const active = idx === activeTabIdx;

                    if (tab.hidden) {
                        return null;
                    }

                    return (
                        <Menu.Item
                            key={tab.id}
                            onClick={() => setActiveTab(idx)}
                            active={active}
                            name={tab.name}
                            icon={<Icon name={tab.icon} color={active ? 'green' : 'black'} />}
                            style={{
                                backgroundColor: 'white',
                                boxShadow: active ? 'inset 0px -2px 0px 0px black' : undefined,
                            }}
                        />
                    );
                })}
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Transition visible={changesMade} duration={2000}>
                            <ColoredText
                                content={changesMade ? 'Unsaved changes' : 'Changes saved!'}
                                icon={changesMade ? 'warning circle' : 'check circle'}
                                color={changesMade ? 'orange' : 'green'}
                                iconPosition='left'
                                padded
                            />
                        </Transition>
                        <Button
                            primary
                            content='Save'
                            icon='save'
                            disabled={!changesMade || isSaving}
                            loading={isSaving}
                            onClick={handleSave}
                        />
                    </Menu.Item>
                    {metadata.outputAsIXBRL && (
                        <Menu.Item> 
                            <PreviewWindowManager
                                metadata={metadata}
                                pdfTemplate={pdfTemplate}
                                inputDefinitions={inputDefinitions}
                                userContext={userContext}
                                textVariations={textVariations}
                                setPdfTemplate={setPdfTemplate}
                            />
                        </Menu.Item>
                    )}
                    <Menu.Item>
                        <FormulaHelp />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Segment attached='bottom'>
                {renderActiveComponent()}
            </Segment>
            <TaxonomyDatalist taxonomy={taxonomy} />
        </div>
    );
};

export default ReportTemplatesDetail;